import React from "react";

import * as heroSection from "./HeroSection.styles";

interface Props {
  onPrimary?: boolean;
  title: string;
  headline?: string;
}

export const HeroSection: React.FC<Props> = ({
  onPrimary = true,
  title,
  headline,
}) => {
  return (
    <section css={heroSection.wrapper}>
      <div
        css={heroSection.container(onPrimary)}
        className="container"
        role="presentation">
        <h1 css={heroSection.title}>{title}</h1>
        <p css={heroSection.headline}>{headline}</p>
      </div>
    </section>
  );
};
