import React, { useState } from "react";

import "./TestimonialCard.scss";

export interface TestimonialCard {
  avatar?: string;
  fullName: string;
  position: string;
  year: string;
  initials?: string;
}

export const TestimonialCard: React.FC<TestimonialCard> = ({
  avatar,
  fullName,
  position,
  year,
  initials = "V",
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <article
      className="testimonial-card"
      onClick={() => setIsActive(!isActive)}>
      <figure className="testimonial-card__figure">
        <img
          className="testimonial-card__image"
          src={
            avatar ?? `https://ui-avatars.com/api/?name=${fullName || initials}`
          }
          alt="Profile picture"
        />
      </figure>
      <section className="testimonial-card__body">
        <h3 className="testimonial-card__title">{fullName}</h3>
        {position && year && (
          <p className="testimonial-card__description">
            {position}, <time dateTime={year}>{year}</time>
          </p>
        )}
      </section>
    </article>
  );
};
