export const homepageContent = {
  heroSection: {
    title: "Urban Sport & Culture Volunteers",
    headline: "What you do makes a difference.",
  },
  sectionTitleDescription: {
    title: "Volunteering opportunities",
    description:
      "Discover upcoming volunteering opportunities, book your place and give back to the community.",
  },
  opportunityOne: {
    id: 1,
    avatarFileUrl: "",
    coverFileUrl: "",
    isOpportunityApplicationVisible: false,
    title: "Pannonian Challenge 2022",
    shortDescription:
      "The Pannonian Challenge was created by the simple idea of a few enthusiasts, which began to be born in the mid-90s. It is the idea of a unique festival that would provide BMX, SKATE and INLINE competitions in a relaxed gathering, while finally a varied program of urban culture in the evening would be rounded off with energetic parties and concerts.",
  },
  opportunityTwo: {
    id: 2,
    avatarFileUrl: "",
    coverFileUrl: "",
    isOpportunityApplicationVisible: false,
    title: "Urbano Dejanje 2022",
    shortDescription:
      "Urbano Dejanje is an urban culture festival held in Ljubljana every year since 2015. The festival is a combo of different artists and urban athletes that gather during summer months in Ljubljana. Performances of renowned musicians of various genres, skate sessions, local creators fair, hip hop workshops, rap battles, film projections, panel talks and many other events make Urbano Dejanje.",
  },
  sectionTitleDescription2: {
    title: "About volunteering",
    description:
      "Create your volunteering account, discover opportunities and take part in various events of your choice.",
  },
  aboutOne: {
    title: "Why?",
    description:
      "Volunteering can be a useful tool in your efforts to improve your social skills through constant interaction and socializing with different people. Volunteering encompasses a wide range of different activities that ultimately bring many benefits for volunteers. It is a great way to find new perspectives on life. Summed up in one sentence: volunteering helps you develop in every way. It contributes to the personal development but also contributes to professional development through the improvement of knowledge and skills.",
    media: {
      src: "",
      alt: "A group of volunteers celebrating and posing for a picture.",
    },
    onPrimary: true,
  },
  aboutTwo: {
    title: "How?",
    description:
      "In addition to filling out the application form, the volunteer must undergo an interview, that is, a selection of volunteers. Organizers are not obliged to invite all registered volunteers for an interview. It is not necessary to have previous experience in volunteering.",
    media: {
      src: "",
      alt: "Two judges. One being serious and watching show, and other posing with a peace sign.",
    },
    onPrimary: true,
  },
  testimonials: {
    title: "Testimonials & nice experiences",
    description:
      "Word of mouth is the best PR you can get, and during the years, we got plenty. Thank you for being a part of this journey!",
    data: [
      {
        testimonialCard: {
          fullName: "Larisa Jakopić",
          position: "",
          year: "",
          initials: "LJ",
        },
        testimonialBody: {
          title: "",
          description:
            "I applied to volunteer at Pannonian in the first place because of the atmosphere and stories of other volunteers and I don't regret it. I met great people, both volunteers and team managers. Seems like every year the atmosphere gets better. During volunteering I learned a lot about about the importance of teamwork and organization. At the end of yet another Pannonian I am already looking forward to the next year. I love how we are all there for one another and it already feels like we are all one small family.",
        },
      },
      {
        testimonialCard: {
          fullName: "Nina Radić",
          position: "",
          year: "",
          initials: "NR",
        },
        testimonialBody: {
          title: "",
          description:
            "Volunteering at Pannonian is a wonderful experience I would recommend to anyone who wants to have fun, but also to learn about work in a big team. Volunteering is not demanding, it is fun and educational. It's great that you are surrounded by your peers and that you make friendships that last. The atmosphere itself on the Pannonian is priceless, from the amount of people and happiness in the air to the athletes and their desire and emotion duirng the contest. The organizers set a wonderful example to us young people how to do their job seriously, but don't leave out the fun.",
        },
      },
      {
        testimonialCard: {
          fullName: "Ira Čurik",
          position: "",
          year: "",
          initials: "IČ",
        },
        testimonialBody: {
          title: "",
          description:
            "I can say with certainty how volunteering at Pannonian is always great. Stress free atmosphere and a lot of parties is what gets me every time. Everything is done easily, quickly and efficiently. All that's needed is a little of good will and a great team. Within the previuos years of me volunteering at Pannonian I learned a lot about sport event management and got to know a lot of amazing people. I established friendships that are still ongoing.",
        },
      },
      {
        testimonialCard: {
          fullName: "Marin Kovačić",
          position: "",
          year: "",
          initials: "MK",
        },
        testimonialBody: {
          title: "",
          description:
            "I volunteered at Pannonian for the first time in 2020. and that was one of the best experiences of my life. I was in infrastructure sector because I wanted to know what it takes to set up such an event. I can honestly say that there was no problems as we were at all times surronded by very positive, professional and interesting people. This experience made me want to volunteer again.",
        },
      },
    ],
  },
};
