import { motion } from "framer-motion";
import React from "react";

export interface AccordionBody {
  description?: string;
  custom?: boolean;
  open: boolean;
}

const animationAccordion = {
  closed: { height: 0 },
  open: { height: "auto" },
};

export const AccordionBody: React.FC<AccordionBody> = ({
  open = false,
  custom = false,
  description,
  children,
}) => {
  return (
    <motion.section
      initial="closed"
      animate={open ? "open" : "closed"}
      variants={animationAccordion}
      transition={{
        duration: 0.4,
        type: "keyframes",
        ease: "easeInOut",
      }}
      className={`accordion__body ${
        open ? "accordion__body--open" : "accordion__body--closed"
      }`}
      aria-hidden={!open}>
      {!custom && (
        <p className="accordion__description">
          {description ? description : "Accordion body text."}
        </p>
      )}
      {custom && children}
    </motion.section>
  );
};
