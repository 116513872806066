import Layout from "../components/layout/Layout";
import React, { useEffect, useState } from "react";
import { HeroSection } from "components/HeroSection/HeroSection";
import { SectionTitleDescription } from "components/SectionTitleDescription/SectionTitleDescription";
import { FeaturedOpportunity } from "components/FeaturedOpportunity/FeaturedOpportunity";
import { AboutCard } from "components/AboutCard/AboutCard";
import { ErasmusBanner } from "components/ErasmusBanner/ErasmusBanner";
import { AccordionWrapper } from "components/Accordion/AccordionWrapper/AccordionWrapper";
import { SEO } from "components/SEO";

import "assets/styles/pages/index-page.scss";

import { homepageContent } from "assets/data/homepage";
import { useIsAuthenticated } from "@azure/msal-react";
import { useGetMyUserProfile } from "modules/user";
import { graphql, PageProps } from "gatsby";
import { getFeaturedOpportunities } from "modules/opportunities/http/getFeaturedOpportunities";

interface DataType {
  pannonianChallengeOpportunity: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  pannonianChallengeLogo: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  urbanoDejanjeOpportunity: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  urbanoDejanjeLogo: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  whyVolunteer: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  howVolunteer: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
}

const IndexPage: React.FC<PageProps<DataType>> = ({ data }) => {
  const isAuthenticated = useIsAuthenticated();

  const [featuredOpportunities, setFeaturedOpportunities] = useState<FeaturedOpportunity[] | undefined>();

  const fetchFeaturedOpportunities = () => {
    getFeaturedOpportunities().then(
      ({ featuredOpportunities }) => {
        setFeaturedOpportunities(featuredOpportunities);
      }
    )
  }

  useEffect(() => {
    if (!isAuthenticated) return;
  }, [isAuthenticated]);

  useEffect(() => {
    if (featuredOpportunities === undefined) {
      fetchFeaturedOpportunities()
    }
  }, [])

  return (
    <Layout>
      <SEO title="Homepage" />

      <HeroSection
        title={homepageContent.heroSection.title}
        headline={homepageContent.heroSection.headline}
      />

      <SectionTitleDescription
        title={homepageContent.sectionTitleDescription.title}
        description={homepageContent.sectionTitleDescription.description}
        onPrimary={false}
      />

      {featuredOpportunities?.map((featuredOpportunity) => (
        <FeaturedOpportunity
          key={featuredOpportunity.id}
          actions={false}
          opportunity={{
            id: featuredOpportunity.id,
            title: featuredOpportunity.title,
            shortDescription: featuredOpportunity.shortDescription,
            avatarFileUrl: featuredOpportunity.avatarFileUrl,
            coverFileUrl: featuredOpportunity.coverFileUrl,
            isOpportunityApplicationVisible: false,
          }}
        />
      ))}

      <article className="section__wrapper--dark">
        <SectionTitleDescription
          title={homepageContent.sectionTitleDescription2.title}
          description={homepageContent.sectionTitleDescription2.description}
          onPrimary
          isCta
          ctaUrl="/about"
        />

        <section className="about-cards">
          <div className="about-cards__container container" role="presentation">
            <AboutCard
              title={homepageContent.aboutOne.title}
              description={homepageContent.aboutOne.description}
              media={{
                src: data.whyVolunteer,
                alt: homepageContent.aboutOne.media.alt,
              }}
              onPrimary
            />
            <AboutCard
              title={homepageContent.aboutTwo.title}
              description={homepageContent.aboutTwo.description}
              media={{
                src: data.howVolunteer,
                alt: homepageContent.aboutTwo.media.alt,
              }}
              onPrimary
            />
          </div>
        </section>
      </article>

      <article className="section__wrapper">
        <SectionTitleDescription
          title={homepageContent.testimonials.title}
          description={homepageContent.testimonials.description}
          onPrimary={false}
        />

        <AccordionWrapper
          testimonials={homepageContent.testimonials.data}
          custom
        />
      </article>
      <ErasmusBanner />
    </Layout>
  );
};

export const query = graphql`
  {
    pannonianChallengeOpportunity: file(
      name: { eq: "pannonian-challenge-opportunity" }
    ) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    pannonianChallengeLogo: file(name: { eq: "pannonian-challenge-logo" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    urbanoDejanjeOpportunity: file(name: { eq: "urbano-dejanje-opportunity" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    urbanoDejanjeLogo: file(name: { eq: "urbano-dejanje-logo" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    whyVolunteer: file(name: { eq: "why-volunteer" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    howVolunteer: file(name: { eq: "how-volunteer" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
  }
`;

export default IndexPage;
