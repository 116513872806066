import React from "react";

import "./TestimonialBody.scss";

export interface TestimonialBody {
  title: string;
  description: string;
}

export const TestimonialBody: React.FC<TestimonialBody> = ({
  title,
  description,
}) => {
  return (
    <section className="testimonial__body">
      <div className="testimonial__container" role="presentation">
        <header className="body__header">
          <span className="header__icon">"</span>
          {title && <h3 className="header__title">{title}</h3>}
        </header>
        <section className="body__content">
          <p className="body__text">{description}</p>
        </section>
      </div>
    </section>
  );
};
