import { css } from "@emotion/react";

export const wrapper = css`
  background-color: var(--color-primary);
  color: var(--text-on-primary);
`;

export const container = (onPrimary: boolean = true) => css`
  display: grid;
  row-gap: 1rem;
  padding-block: 5rem;
  color: ${onPrimary ? `var(--text-on-primary)` : `var(--text-body)`};

  @media screen and (min-width: 48rem) {
    padding-block: 9rem;
  }
`;

export const title = css`
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-sm);
`;

export const headline = css`
  --fluid-type-min: 3.4375rem;
  --fluid-type-target: 5vw;
  --fluid-type-max: 5rem;
  --line-height-ratio: 1.182;

  font-family: var(--font-primary);
  font-size: clamp(
    var(--fluid-type-min),
    var(--fluid-type-target),
    var(--fluid-type-max)
  );
  line-height: var(--line-height-ratio);
  font-weight: var(--font-weight-normal);
  max-width: 15ch;
`;
