import { motion } from "framer-motion";
import React from "react";

import "./AccordionHeader.scss";

export interface AccordionHeader {
  title?: string;
  custom?: boolean;
  open: boolean;
  onClick: any;
}

export const AccordionHeader: React.FC<AccordionHeader> = ({
  custom = false,
  title,
  open,
  onClick,
  children,
}) => {
  return (
    <motion.header
      tabIndex={1}
      className={`accordion__header ${
        open ? "accordion__header--open" : "accordion__header--closed"
      }`}
      aria-expanded={open}
      onClick={onClick}>
      {!custom && (
        <h2 className="accordion__title">
          {title ? title : "Accordion title"}
        </h2>
      )}
      {custom && children}
    </motion.header>
  );
};
