import { motion } from "framer-motion";
import React, { useState } from "react";
import { AccordionBody } from "./AccordionBody/AccordionBody";
import { AccordionHeader } from "./AccordionHeader/AccordionHeader";

import "./Accordion.scss";

export interface Accordion {
  title?: string;
  description?: string;
  header?: React.ReactNode;
  body?: React.ReactNode;
  custom?: boolean;
  isOpen: boolean;
  // setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: (panel: string) => void;
}

export const Accordion: React.FC<Accordion> = ({
  title,
  description,
  header,
  body,
  custom,
  children,
  isOpen,
  setIsOpen,
}) => {
  const [open, setOpen] = useState(false);

  // Handle activating the Accordion component using Enter or Space keys
  const handleKeyboardPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      e.stopPropagation();

      // setIsOpen(!isOpen);
    }
  };
  return (
    <motion.article
      tabIndex={1}
      className="accordion"
      onKeyDown={handleKeyboardPress}
      transition={{
        ease: [0.77, 0.0, 0.175, 1.0],
        staggerChildren: 0.2,
      }}>
      <AccordionHeader
        title={title}
        open={isOpen}
        onClick={setIsOpen}
        custom={custom}>
        {header}
      </AccordionHeader>

      <AccordionBody description={description} open={isOpen} custom={custom}>
        {body}
      </AccordionBody>
    </motion.article>
  );
};
