export const getFeaturedOpportunities = async () => {
  const response = await fetch(
    `${process.env.BASE_API_URL}/Opportunity/GetFeaturedOpportunites`,
    {
      method: "GET",
    }
  );

  const { opportunities } = await response.json();

  console.log("getFeaturedOpportunities opportunities: ", opportunities);

  return {
    featuredOpportunities: opportunities,
  };
};
