import { Testimonial } from "components/Testimonial/Testimonial";
import { TestimonialBody } from "components/Testimonial/TestimonialBody/TestimonialBody";
import { TestimonialCard } from "components/Testimonial/TestimonialCard";
import React, { useState } from "react";
import { Accordion } from "../Accordion";
import { AccordionBody } from "../AccordionBody/AccordionBody";
import { AccordionHeader } from "../AccordionHeader/AccordionHeader";

import "./AccordionWrapper.scss";

export interface AccordionWrapper {
  testimonials?: Testimonial[];
  title?: string;
  description?: string;
  header?: React.ReactNode;
  body?: React.ReactNode;
  custom?: boolean;
}

export const AccordionWrapper: React.FC<AccordionWrapper> = ({
  testimonials,
  title,
  description,
  header,
  body,
  custom = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(0);

  const handleToggle = (panel: number) => {
    setIsOpen(panel);
  };

  return (
    <section className="accordions">
      <div className="accordions__container container" role="presentation">
        {testimonials?.map((testimonial, index) => {
          return (
            <Accordion
              key={testimonial.testimonialCard.fullName}
              custom={custom}
              title={title}
              description={description}
              isOpen={isOpen === index}
              setIsOpen={() => handleToggle(index)}
              header={<TestimonialCard {...testimonial.testimonialCard} />}
              body={
                <TestimonialBody {...testimonial.testimonialBody} />
              }></Accordion>
          );
        })}
      </div>
    </section>
  );
};
